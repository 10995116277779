import {Link} from "react-router-dom";

export function Help(props) {
    return (
        <div className='delivery-content'>
            <div className='content wrapper flex-1'>
                <h3>Контакты и помощь</h3>
                <p>
                    Мы уделяем особое внимание качеству продукции и сервису. Нам важно становиться лучше и удобнее для вас. Возник вопрос? Есть предложение? Что-то не устроило? Сообщите нам! мы всегда на связи.
                </p>
                <Link className='contact-link'>WhatsApp</Link>
                <Link className='contact-link'>E-mail</Link>
                <Link className='contact-link'>Телефон</Link>
            </div>

        </div>

    )
}